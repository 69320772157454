import { createStore } from 'vuex'

export default createStore({
  state: {
    isSidebarOpen: parseInt(localStorage.getItem('isSidebarOpen') ?? 1) === 1,
  },

  mutations: {
    setIsSidebarOpen(state, value) {
      state.isSidebarOpen = value
      localStorage.setItem('isSidebarOpen', value)
    },
  },

  actions: {
    openSidebar({ commit }) {
      commit('setIsSidebarOpen', 1)
    },

    closeSidebar({ commit }) {
      commit('setIsSidebarOpen', 0)
    },
  },
})
