import { toRefs } from 'vue'
import { usePage } from '@inertiajs/vue3'
import { toast } from 'vue3-toastify'

interface FlashObject {
  success?: string
  error?: string
  warning?: string
  info?: string
}

export function useSessionFlashMessage() {
  const getMessageTypeAndText = (flashObject?: FlashObject) => {
    if (!flashObject) return null

    for (const [type, message] of Object.entries(flashObject)) {
      if (message) return { type, message }
    }

    return null
  }

  const flashMessage = () => {
    const page = usePage()
    const { flash: flashObject } = toRefs(page.props)

    if (!flashObject.value) return

    const flash = getMessageTypeAndText(flashObject.value)
    if (!flash) return

    const { type, message } = flash
    switch (type) {
      case 'success':
        toast.success(message)
        break
      case 'error':
        toast.error(message)
        break
      case 'warning':
        toast.warning(message)
        break
      case 'info':
        toast.info(message)
        break
      default:
        toast(message)
    }
  }

  return { flashMessage }
}
